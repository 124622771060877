import {initSlider} from "./slider-init";

export const sliders = document.querySelectorAll('[id^=\'felbermeile-slider\']');
export const swipers = [];

if (sliders?.length) {
    Array.from(sliders).forEach((slider) => {
        const container = slider.querySelector('.swiper');
        const swiper = initSlider(container, slider.id);
        swipers.push(swiper);
    });
}
