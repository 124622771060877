const counters = document.querySelectorAll('[id^="counter-"]');

const arrowElements = document.querySelectorAll('.fm-arrow');
const footerArrowElement = document.querySelector('.fm-arrow-footer');
let arrows = Array.from(arrowElements);
let notAnimatedArrows = [];
arrows.forEach((arrow) => {
    if (arrow.parentElement.classList.contains('references-teaser-item') || arrow.parentElement.classList.contains('employee')) {
        notAnimatedArrows.push(arrow);
        arrows.concat(arrow);
        arrow.setAttribute('animated', '');
    }
});

document.addEventListener('scroll', (e) => {

    counters.forEach((counter) => {
        let counted;
        let pos = counter.offsetTop;
        const oTop = pos - window.innerHeight;
        counted = counter.classList.contains('counted');

        if (!counted && document.documentElement.scrollTop > oTop) {
            const counter_counters = counter.querySelectorAll('.counter');
            counter_counters.forEach((counter) => {
                counterAnimation(counter, 0, counter.getAttribute('data-to'))
            })
            counter.classList.add('counted')
        }
    })

    arrows.forEach((arrow, index) => {
        const alreadyAnimated = arrow.hasAttribute('animated')
        if (alreadyAnimated) return;

        if ((window.scrollY + window.innerHeight) > (arrow.getBoundingClientRect().bottom + window.scrollY + 300)) {
            arrow.style.top = 0;
            arrow.style.right = '-3px';
            arrow.setAttribute('animated', '');
            arrows = arrows.filter((element) => element !== arrow);
        }
    })

    if ((window.scrollY + window.innerHeight) > (footerArrowElement.getBoundingClientRect().bottom + window.scrollY) + 150) {
        if (footerArrowElement.hasAttribute('animated')) return;
        footerArrowElement.style.top = '-50px';
        footerArrowElement.style.right = '-2px';
        footerArrowElement.setAttribute('animated', '');
    }
});

const counterAnimation = (item, start = 0, end, duration = 2000) => {
    let startTimestamp = null;

    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        item.innerText = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    }
    window.requestAnimationFrame(step);
}
