import Swiper, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";

export const initSlider = (container, id) => {
    if (container) {
        const slides = container.querySelectorAll('.swiper-slide');

        if (!slides) {
            return null;
        }

        return new Swiper(container, {
            modules: [Pagination, Autoplay, EffectFade, Navigation],
            autoHeight: true,
            loop: true,
            pagination: {
                el: `#swiper-pagination-${id}`,
                clickable: true,
            },
            navigation: {
                nextEl: `#swiper-button-next-${id}`,
                prevEl: `#swiper-button-prev-${id}`,
            },

            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            shortSwipes: false,
        });
    }

    return null;
};
