const accordionButtonHandler = (() => {
    const accordionButtons = document.querySelectorAll('.accordion-button');

    accordionButtons.forEach((accordionButton) => {
        accordionButton.addEventListener('click', (e) => {
            const parentContainer = e.target.closest('.accordion-wrapper');
            const wasOpen = parentContainer.classList.contains('bg-white');

            e.target.closest('.accordion').querySelectorAll('.accordion-wrapper').forEach((accordionWrapper) => {
                if (accordionWrapper !== parentContainer || parentContainer.classList.contains('employee-accordion')) {
                    accordionWrapper.classList.remove('bg-white');
                }
            })

            if (!wasOpen) {
                parentContainer.classList.toggle('bg-white');
            }
        });
    })

    return {}
})();
