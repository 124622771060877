// Lazysizes
import 'lazysizes';

document.addEventListener('lazyloaded', (e) => {
    e.target.style.backgroundColor = '';
});

import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';

// Blocks
import '../_blocks/index';

// Partials
import './partials/header'

// Partials
import './partials/header'

// Scripts
import '../_assets/scripts/scroll-listener'
