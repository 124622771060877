let scrollTimeout = null;
let oldScroll = 0;
const navigation = document.getElementById('felbermeile-navigation')
const hero = document.querySelector('.hero')

window.onscroll = () => {
    if (scrollTimeout) {
        clearTimeout(scrollTimeout)
    }

    scrollTimeout = setTimeout(() => {
        if (window.scrollY > 0) {
            navigation.classList.add('is-sticky');
            hero.style.paddingTop = '80px';
        } else {
            navigation.classList.remove('is-sticky');
            hero.style.paddingTop = '100px';
        }

        oldScroll = window.scrollY;
    }, 50);
};

const MobileNavbarToggler = (() => {
    const navbarToggler = document.querySelector('#mobile-navbar-toggler');
    const closeButton = document.querySelector('#close-nav');

    navbarToggler.addEventListener('click', (e) => {
        const mobileNavbar = document.querySelector('#mobile-navbar-felbermeile');
        mobileNavbar.classList.toggle('start-0');
        document.body.classList.toggle('no-scroll');
    });

    closeButton.addEventListener('click', (e) => {
        const mobileNavbar = document.querySelector('#mobile-navbar-felbermeile');
        mobileNavbar.classList.toggle('start-0');
        document.body.classList.toggle('no-scroll');
    });

    return {}
})();
