import {swipers as modalSwipers} from '../_layout/slider';
import {initSlider} from '../_layout/slider-init';
import {Modal} from 'bootstrap';

const modalSliders = document.querySelectorAll('[id^=\'felbermeile-modal-slider-wrapper-\']');

if (modalSliders?.length) {
    Array.from(modalSliders).forEach((modalSlider) => {
        const sliderWrapper = modalSlider.querySelector('[id^=\'felbermeile-modal-slider-slider-\']')

        const sliderContainer = sliderWrapper.querySelector('.swiper');
        const swiper = initSlider(sliderContainer, sliderWrapper.id);

        const modalContainer = modalSlider.querySelector('.modal');
        const modal = new Modal(modalContainer);

        const innerSwiper = modalSwipers.find((modalSwiper) => modalContainer.contains(modalSwiper.el))
        swiper.on('click', (_, event) => {
            innerSwiper.slideTo(swiper.clickedIndex);
            modal.show();
        });
    });
}
